<template>

  <b-card-code>

    <!-- forms -->
    <validation-observer ref="simpleRules">
      <b-form @submit.prevent>
        <b-row col>
          <b-col md="6">
            <b-form-group
              id="name"
              label="Name"
              label-for="mc-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Language Name"
                rules="required|alpha|max:60"
              >
                <b-form-input
                  id="languageName"
                  v-model="LanguageEditForm.name"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Language Name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="iso_code_1"
              label="ISO-Code-1"
              label-for="mc-ISO-Code-1"
            >
              <validation-provider
                #default="{ errors }"
                name="ISO-Code-1"
                rules="required|max:20"
              >
                <b-form-input
                  id="languageIsoCode1"
                  v-model="LanguageEditForm.iso_1_code"
                  disabled
                  :state="errors.length > 0 ? false:null"
                  placeholder="ISO_Code_1"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              id="iso_code_2"
              label="ISO-Code-2"
              label-for="mc-ISO-Code-2"
            >
              <validation-provider
                #default="{ errors }"
                name="ISO-Code-2"
                rules="required|max:20"
              >
                <b-form-input
                  id="languageIsoCode2"
                  v-model="LanguageEditForm.iso_2_code"
                  disabled
                  :state="errors.length > 0 ? false:null"
                  placeholder="ISO_Code_2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- submit button -->
          <b-col md="12" class="demo-inline-spacing">
            <b-button
              class="updateLanguage"
              :disabled="loading"
              variant="primary"
              type="submit"
              @click="updateLanguage"
            >
              Submit
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
            <b-button
              v-if="checkLoginRole() === RoleEnum.SuperAdmin ? true : checkAccess.view"
              variant="secondary"
              :to="{name: ((checkLoginRole() === RoleEnum.SuperAdmin) || checkAccess.view)?'language-list':'dashboard'}"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import {
  required, email, confirmed, alpha, max, regex
} from 'vee-validate/dist/rules'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import errorResponseHandler from '@/services/errorHandler'
import { resConditionCheck, RoleEnum } from '@/helpers/constant'
import checkLoginRole from '@/helpers/checkLoginRole'
import accessRightCheck from '@/helpers/accessRightCheck'
import languageService from '../../../services/language/language.service'

extend('required', required)
extend('email', email)
extend('max', max)
extend('alpha', alpha)
extend('regex', regex)

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,

  },
  data() {
    return {
      LanguageEditForm: {
        name: "",
        iso_1_code: "",
        iso_2_code: "",
      },
      required,
      email,
      confirmed,
      alpha,
      loading: false,
      RoleEnum,
      checkAccess: {},
      checkLoginRole,
    }
  },

  async mounted() {
    this.checkAccess = accessRightCheck(this.$route.meta.module.name)
    if (this.$route.params.id) {
      await this.getLanguageById(this.$route.params.id)
    }
  },

  methods: {

    getLanguageById(id) {
      languageService.getLanguageById(id).then(res => {
        if (resConditionCheck(res.status) && res.data.data) {
          this.LanguageEditForm.name = res.data.data.name
          this.LanguageEditForm.iso_1_code = res.data.data.iso_1Code
          this.LanguageEditForm.iso_2_code = res.data.data.iso_2Code
        }
      }).catch(error => {
        const errorData = errorResponseHandler(error)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      })
    },

    updateLanguage() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          languageService.updateLanguage(this.$route.params.id, this.LanguageEditForm).then(res => {
            if (resConditionCheck(res.status) && res.data.data) {
              this.loading = false
              this.$router.push('/language/list')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "CheckIcon",
                  variant: "success",
                },
              })
            }
          }).catch(error => {
            this.loading = false
            const errorData = errorResponseHandler(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger",
              },
            });
          })
        }
      })
    }

  }
}
</script>
